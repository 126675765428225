import { useContext } from "react";
import { Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import MapContext from "context/MapContext";
import UIContext from "context/UIContext";
import { ViewEnum, ViewType } from "custom-types/Finder";
import useWindowSize from "hooks/useWindowSize";
import { getLocationState } from "redux/selectors/location";
import { hasFullAddress } from "utils/hasFullAddress";
import slugifyName from "utils/slugifyName";

import StoreCard from "components/Finder/FinderListings/StoreCard";
import Geolocator from "components/Finder/FinderMap/MapOverlays/Geolocator";
import SearchButton from "components/Finder/FinderMap/MapOverlays/SearchButton";
import ZoomControls from "components/Finder/FinderMap/MapOverlays/ZoomControls";

import Spotlight from "./Spotlight";

const MapOverlays: React.FC<{
  listingPosition: ViewType;
  finderMigrationFlag?: boolean;
}> = ({ listingPosition, finderMigrationFlag }) => {
  const { isMobile } = useWindowSize();
  const { spotlightDismissed } = useContext(UIContext);
  const userLocation = useSelector(getLocationState);

  const { selectedStore, showSearchButton, spotlight, loadingMapMarkers } =
    useContext(MapContext);

  const { query } = useRouter();

  const hasOrganizationId = !!query.organization_id;

  const isDeliveryTab = !!query.filter?.includes("delivery");
  const hasDeliveryAddressSet = isDeliveryTab && hasFullAddress(userLocation);
  const hideSearchArea = hasOrganizationId || hasDeliveryAddressSet;

  return (
    <>
      {loadingMapMarkers && listingPosition === ViewEnum.MAP && (
        <div className="absolute flex justify-center top-0 left-0 right-0">
          <SearchButton isLoading>Loading markers</SearchButton>
        </div>
      )}
      {!hideSearchArea &&
        showSearchButton &&
        listingPosition !== ViewEnum.LIST && (
          <div className="absolute flex justify-center top-0 left-0 right-0">
            <SearchButton>Search this area</SearchButton>
          </div>
        )}

      <div className="absolute right-0 top-0 m-md flex flex-col">
        <Geolocator />
        <ZoomControls />
      </div>

      <div className="absolute bottom-0 right-0 left-0 md:w-fit">
        <Transition
          className="transition md:m-lg"
          show={
            isMobile
              ? !spotlightDismissed && !selectedStore
              : !spotlightDismissed
          }
          enterFrom="translate-y-[100%] opacity-0"
          enterTo="translate-y-0 opacity-1"
          leaveFrom="translate-y-0 opacity-1"
          leaveTo="translate-y-[100%] opacity-0"
        >
          {spotlight && (
            <Spotlight
              store={spotlight}
              isAd
              finderMigrationFlag={finderMigrationFlag}
            />
          )}
        </Transition>
      </div>
      <div className="absolute bottom-0 right-0 left-0 md:hidden">
        <Transition
          className="transition m-lg"
          show={!!selectedStore}
          enterFrom="translate-y-[100%] opacity-0"
          enterTo="translate-y-0 opacity-1"
          leaveFrom="translate-y-0 opacity-1"
          leaveTo="translate-y-[100%] opacity-0"
        >
          {selectedStore && (
            <StoreCard
              store={selectedStore}
              compact
              hideOnMobile={true}
              id={`${slugifyName(
                selectedStore.name,
              )}-mobile-overlay-store-card`}
              index={0}
            />
          )}
        </Transition>
      </div>
    </>
  );
};

export default MapOverlays;
