import { memo, useContext } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import Link from "next/link";

import ConfigContext from "context/ConfigContext";
import isMobileDevice from "utils/isMobileDevice";
import { getSplitKey } from "utils/split/getSplitKey";

import TrackImpression from "components/TrackImpression";

import CityCard from "./CityCard";

export type Filter = "all" | "medical" | "recreational";

type Props = {
  heading?: string;
  finderSiteMapFlag?: boolean;
};

const CitiesNearby = ({ heading, finderSiteMapFlag }: Props) => {
  const { cities } = useContext(ConfigContext);

  const sitemapTrackingOnClick = () => {
    sendClientSideSplitTrackEvent(
      {
        eventType: "nl472-finder-sitemap_link_click",
        properties: { isMobile: isMobileDevice() },
      },
      getSplitKey(),
    );
  };

  return (
    <div data-testid="cities-nearby-wrapper" role="complementary">
      {cities.length > 0 && (
        <div className="container my-xxl" data-testid="cities-nearby">
          {heading && (
            <TrackImpression category="Cities Near You" label="Cities Near You">
              <h2 className="mb-sm" data-testid="cities-nearby-heading">
                {heading}
              </h2>
            </TrackImpression>
          )}
          <div className="grid grid-cols-2 gap-sm ">
            {cities.map((city, idx) => (
              <CityCard data={city} key={idx} extraClasses="shadow-low" />
            ))}
          </div>
          {finderSiteMapFlag && (
            <div className="pt-xs">
              <Link
                className="text-xs font-bold underline text-green pl-px"
                href="/dispensaries/locations"
                onClick={sitemapTrackingOnClick}
              >
                Find all locations
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(CitiesNearby);
