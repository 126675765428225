import { useContext } from "react";

import ConfigContext from "context/ConfigContext";
import LocationContext from "context/LocationContext";
import { FinderBreadcrumbLocation } from "custom-types/Finder";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { getLocationRoute } from "utils/locationUtils";

import Breadcrumb from "components/Breadcrumb";
import Breadcrumbs from "components/Breadcrumbs";

const FinderBreadcrumbs = () => {
  const { finderLocation: location } = useContext(LocationContext);

  const finderLocation = location as FinderBreadcrumbLocation;

  const domainCountryCode = useDomainCountryCode();

  const { finderRoute, title } = useContext(ConfigContext);

  const generateUrl = (type: string) => {
    switch (type) {
      case "home":
        return "/";
      case "siteMap":
        return "/dispensaries/locations";
      case "activeView":
        return finderRoute;
      case "state":
        return `${getLocationRoute(finderRoute, {
          state: finderLocation.state,
        })}`;
      case "city":
        return `${getLocationRoute(finderRoute, {
          city: finderLocation.city,
          slug: finderLocation.geoSlug,
          state: finderLocation.state,
        })}`;
      case "zip":
        return `${getLocationRoute(
          finderRoute,
          finderLocation as FinderBreadcrumbLocation,
        )}`;
      default:
        return "";
    }
  };

  const arrayOfLinks: { label: string; url?: string }[] = [
    { label: title, url: generateUrl("activeView") },
    {
      label: domainCountryCode === "CA" ? "Canada" : "United States",
      url: generateUrl("siteMap"),
    },
  ];

  if (finderLocation.state) {
    arrayOfLinks.push({
      label: finderLocation.state,
      url: generateUrl("state"),
    });
  }
  if (finderLocation.city) {
    arrayOfLinks.push({
      label: finderLocation.city,
      url: generateUrl("city"),
    });
  }

  if (finderLocation.isUserLocation) {
    arrayOfLinks.push({ label: "My location" });
  } else if (finderLocation.zip || finderLocation?.street?.name) {
    const streetNumber = finderLocation?.street?.number || "";
    const streetName = finderLocation?.street?.name || "";
    const zip = finderLocation.zip || "";
    const shouldHaveComma =
      finderLocation?.street?.number &&
      finderLocation?.street?.name &&
      finderLocation.zip
        ? ", "
        : "";
    arrayOfLinks.push({
      label: `${streetNumber} ${streetName}${shouldHaveComma}${zip}`,
    });
  }

  return (
    <Breadcrumbs>
      {arrayOfLinks.map(({ url, label }, idx) => (
        <Breadcrumb
          key={label}
          href={url}
          currentLocation={idx === arrayOfLinks.length - 1}
        >
          {label}
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
};

export default FinderBreadcrumbs;
