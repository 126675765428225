import React, { useCallback, useContext } from "react";
import GoogleMapReact, { ClickEventValue, Coords } from "google-map-react";
import { useSelector } from "react-redux";

import publicConfig from "config/public";
import { Action, Category } from "constants/events";
import FinderResultsContext from "context/FinderResultsContext";
import MapContext from "context/MapContext";
import { Location } from "custom-types/Location";
import { Store } from "custom-types/Store";
import { useEventTracker } from "hooks/useEventTracker";
import { getLocationState } from "redux/selectors/location";
import { createMapOptions } from "utils/finder/mapUtils";

import MeMarker from "components/Finder/FinderMap/MeMarker";

import MapMarker from "./MapMarker";

type Props = {
  userLocation?: Location;
  setListPosition: (a: number) => void;
};

const GoogleMap = ({ setListPosition }: Props) => {
  const {
    mapCenter: { lat, lon },
    zoomLevel,
    handleMapChange,
    selectedStore,
    setSelectedStore,
    setShowSearchButton,
    setMapInitialized,
    setMapReady,
    hoveredStore,
  } = useContext(MapContext);

  const { publishEvent } = useEventTracker();

  const userLocation = useSelector(getLocationState);
  const { mapMarkers } = useContext(FinderResultsContext);

  const meMarker = userLocation?.coordinates;

  const shouldShowMeMarker =
    userLocation?.isUserLocation ||
    (userLocation?.street?.name?.length &&
      userLocation.street.name.length > 0 &&
      userLocation.street.number.length > 0);

  const initializeMapListeners = ({
    map,
    maps,
  }: {
    map: google.maps.Map;
    maps: google.maps.CoreLibrary;
  }) => {
    setMapInitialized(true);

    maps.event.addListener(map, "dragend", () => {
      publishEvent({
        action: Action.click,
        category: Category.map,
        label: "map drag",
      });
      setShowSearchButton(true);
    });
  };

  const _setSelectedStore = (classList: DOMTokenList) => {
    if (!classList.contains("finder-map-marker")) {
      setSelectedStore(undefined);
    }
  };

  const handleClick = useCallback(
    ({ event }: { event: React.MouseEvent<ClickEventValue> }) => {
      _setSelectedStore((event.target as Element).classList);
    },
    [],
  );

  const handleMapReady = () => {
    setMapReady(true);
  };

  const center: Coords = { lat, lng: lon };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: publicConfig.googleMapsApiKey,
        libraries: ["geometry", "places"],
      }}
      resetBoundsOnResize={true}
      hoverDistance={45}
      center={center}
      options={createMapOptions()}
      onChange={handleMapChange}
      onClick={handleClick}
      onGoogleApiLoaded={initializeMapListeners}
      zoom={zoomLevel}
      onTilesLoaded={handleMapReady}
      shouldUnregisterMapOnUnmount={false}
      onDragEnd={() => setShowSearchButton(true)}
      yesIWantToUseGoogleMapApiInternals={true}
      onZoomAnimationStart={setListPosition}
    >
      {mapMarkers?.map((mapMarker, idx) => {
        if (!mapMarker.lat || !mapMarker.lon) {
          return null;
        }
        return (
          <MapMarker
            lat={Number(mapMarker.lat)}
            lng={Number(mapMarker.lon)}
            key={`map-marker-${idx}`}
            mapMarker={mapMarker}
            isSelected={isMapMarkerSelected(mapMarker, selectedStore)}
            isHovered={hoveredStore === mapMarker.id}
            setSelectedStore={setSelectedStore}
          />
        );
      })}
      <MeMarker
        lat={Number(meMarker?.lat)}
        lng={Number(meMarker?.lon)}
        shouldShowMeMarker={!!shouldShowMeMarker}
      />
    </GoogleMapReact>
  );
};

const isMapMarkerSelected = (
  mapMarker: Store,
  selectedStore: Store | undefined,
) => {
  return (
    selectedStore &&
    mapMarker.id === selectedStore.id &&
    selectedStore.lat === mapMarker.lat &&
    selectedStore.lon === mapMarker.lon
  );
};

export default GoogleMap;
