import { Ref, useContext, useEffect, useMemo, useRef } from "react";
import { Transition } from "@headlessui/react";
import classnames from "classnames";
import Image from "next/legacy/image";
// eslint-disable-next-line no-restricted-imports -- Boundary used for google maps component
import { ErrorBoundary } from "react-error-boundary";

import publicConfig from "config/public";
import FinderResultsContext from "context/FinderResultsContext";
import MapContext from "context/MapContext";
import { ViewEnum, ViewType } from "custom-types/Finder";
import useWindowSize from "hooks/useWindowSize";

import GoogleMap from "components/Finder/FinderMap/GoogleMap";
import SearchButton from "components/Finder/FinderMap/MapOverlays/SearchButton";

import { blurMapImage } from "./blurMapImage";
import MapOverlays from "./MapOverlays";
import styles from "./MapWrapper.module.css";

const DESKTOP_HEIGHT_OFFSET = 138;
const DESKTOP_WIDTH_OFFSET = 386;
const MOBILE_HEIGHT_OFFSET = 174;

type Props = {
  listingPosition: ViewType;
  setListingPosition: (arg: ViewType) => void;
  trackMobileViewClickEvent: (listingPosition: ViewType) => void;
  finderMigrationFlag?: boolean;
};

const {
  imgix: { publicUrl },
} = publicConfig;

const MapWrapper = ({
  listingPosition,
  setListingPosition,
  trackMobileViewClickEvent,
  finderMigrationFlag,
}: Props) => {
  const { pageInitialized } = useContext(FinderResultsContext);

  const {
    mapContainerEl,
    setMapContainerEl,
    mapReady,
    zoomLevel,
    staticMapURL,
    setStaticMapURL,
  } = useContext(MapContext);

  const mapRef: Ref<HTMLDivElement> = useRef(null);

  const {
    height: windowHeight = 0,
    width: windowWidth = 0,
    isMobile,
  } = useWindowSize();

  const height = isMobile
    ? windowHeight - MOBILE_HEIGHT_OFFSET
    : windowHeight - DESKTOP_HEIGHT_OFFSET;
  const width = isMobile ? windowWidth : windowWidth - DESKTOP_WIDTH_OFFSET;

  useEffect(() => {
    if (height > 0 && width > 0 && !staticMapURL) {
      const staticMapImageURL = `${publicUrl}/web-web/finder/finder-map-placeholder.jpg?auto=compress,format&fit=crop&w=${width}&h=${height}&dpr=1`;
      setStaticMapURL(staticMapImageURL);
    }
  }, [height, width]);

  useEffect(() => {
    if (!mapContainerEl || mapContainerEl !== mapRef.current) {
      if (mapRef?.current) {
        setMapContainerEl(mapRef.current);
      }
    }
  }, [mapContainerEl, mapRef.current]);

  const handleTouch = () => {
    if (listingPosition === ViewEnum.HALF) {
      setListingPosition(ViewEnum.MAP);
      trackMobileViewClickEvent(ViewEnum.MAP);
    }
  };

  const handleZoom = (zoom: number) => {
    if (zoom !== zoomLevel && listingPosition === ViewEnum.HALF) {
      setListingPosition(ViewEnum.MAP);
      trackMobileViewClickEvent(ViewEnum.MAP);
    }
  };

  return (
    <div
      className={`${styles.wrapper} finder-map-wrapper`}
      onTouchStart={handleTouch}
      data-testid="map-wrapper-v2"
      role="region"
      aria-hidden="true"
    >
      <div className={styles.mapHeight} ref={mapRef}>
        <ErrorBoundary
          FallbackComponent={() =>
            MapErrorFallback(staticMapURL, width, height, isMobile)
          }
        >
          {staticMapURL && (
            <Transition
              show={!pageInitialized || !mapReady}
              className="absolute top w-full transition duration-1000 data-[closed]:opacity-0"
            >
              <MapPlaceholder
                url={staticMapURL}
                height={height}
                width={width}
              />
            </Transition>
          )}

          {/**
           * It would be nice to use the `Transition` component here, but the `GoogleMap`
           * component needs to be rendered and not-hidden in order for the "readiness" event
           * to fire and set the `mapReady` state. We'll fall back to setting an opacity class
           * instead, which allows the map to load and satisfies the "fade in" transition that
           * we want here.
           */}
          <div
            className={classnames(
              // delay here displays the map only after the zoom level has been set
              "h-full w-full transition delay-300 duration-1000",
              {
                "opacity-0": !mapReady,
              },
            )}
          >
            <GoogleMap setListPosition={handleZoom} />
            <MapOverlays
              listingPosition={listingPosition}
              finderMigrationFlag={finderMigrationFlag}
            />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default MapWrapper;

type MapPlaceholderProps = {
  url: string;
  height: number;
  width: number;
};

const MapPlaceholder = ({ url, height, width }: MapPlaceholderProps) =>
  useMemo(
    () => (
      <div className="relative h-full w-full">
        <div className="text-center mb-lg w-full absolute top z-overlay">
          <SearchButton isLoading>Loading markers</SearchButton>
        </div>
        {url && <StaticMap url={url} height={height} width={width} />}
      </div>
    ),
    [url],
  );

const StaticMap = ({
  url,
  height,
  width,
}: {
  url: string;
  height: number;
  width: number;
}) => (
  <Image
    data-testid="finder-static-map"
    src={url}
    loader={({ src }) => src}
    height={height}
    width={width}
    alt="map placeholder"
    decoding="async"
    placeholder="blur"
    blurDataURL={blurMapImage}
    priority
    unoptimized
  />
);

const MapErrorFallback = (
  staticMapURL: string | undefined,
  width: number,
  height: number,
  isMobile = true,
) => (
  <div className="flex h-full w-full bg-leafly-white">
    {staticMapURL && (
      <div className="absolute">
        <StaticMap url={staticMapURL} height={height} width={width} />
      </div>
    )}
    <div
      className={classnames(
        "bg-transparent h-full w-full flex justify-center px-xl",
        {
          "bg-leafly-white": !staticMapURL,
          "items-center": !isMobile,
        },
      )}
      style={{ backdropFilter: "blur(8px)" }}
    >
      <div
        className={classnames("bg-transparent")}
        data-testid="map-error-message"
      >
        <h2 className="text-lg">Sorry, there was a problem loading the map!</h2>
        <p>Try refreshing the page to continue.</p>
      </div>
    </div>
  </div>
);
