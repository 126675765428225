import React, { useContext } from "react";
import { useRouter } from "next/router";

import { City } from "api/requests/getCitiesNearby";
import ConfigContext from "context/ConfigContext";
import { RetailType } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { getRetailTypeFromUrl } from "utils/finder/getContextData";
import { getStateOrProvinceLongName } from "utils/getStatesForCountry";
import { localizeText } from "utils/localizeText";
import { getLocationRoute, getUrlLocationPart } from "utils/locationUtils";

import FinderLinkButton from "../FinderLinkButton";

type Props = {
  data: City;
  extraClasses?: string;
};

const CityCard = ({
  data: { city, count, distance, distanceUnit, state },
  extraClasses,
}: Props) => {
  const { finderRoute } = useContext(ConfigContext);

  const { asPath } = useRouter();
  const retailType = getRetailTypeFromUrl(asPath);

  const countryCode = useDomainCountryCode();
  const typeTitle: Record<RetailType, string> = {
    "cbd-store": count > 1 ? "CBD Stores" : "CBD Store",
    clinic: count > 1 ? "MMJ Doctors" : "MMJ Doctor",
    dispensary: localizeText(
      count > 1 ? "dispensaries" : "dispensary",
      countryCode,
    ),
  };

  const retailerCountUnits = typeTitle[retailType];

  return (
    <FinderLinkButton
      link={`${getLocationRoute(finderRoute, {
        city,
        slug: `${getUrlLocationPart(city)}-${state.toLowerCase()}`,
        state: getStateOrProvinceLongName(state),
      })}`}
      header={`${city}, ${state}`}
      content={
        <CityContent
          distance={`${distance.toFixed(1)} ${distanceUnit}`}
          count={`${count} ${retailerCountUnits}`}
        />
      }
      className={`block h-full ${extraClasses}`}
      btnClasses="h-full"
      trackingInfo={{
        category: "Cities Near You",
        label: `Online Ordering Slot: ${city}, ${state}`,
      }}
    />
  );
};

const CityContent = ({
  distance,
  count,
}: {
  distance: string;
  count: string;
}) => (
  <>
    {distance}
    <br />
    {count}
  </>
);

export default CityCard;
